// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

import Login from './components/Login/Login';
import Cmpage from './components/Cmpage/Cmpage';
import CMAddPage from './components/CMAddPage/CMAddPage';
import CmDetailPage from './components/CmDetailPage/CmDetailPage';
import Cmgraphpage from './components/CmGraphPage/Cmgraphpage';
// import Menu from './components/Menu/Menu'
import Updateboard from './components/Updateboard/Updateboard';
import Newupdateboard from './components/Newupateboard/Newupdateboard'
import Detailupdateboard from './components/Detailupdateboard/Detailupdateboard';
import Selectserialnumber from './components/Selectserialnumber/Selectserialnumber';
import Detailserialnumber from './components/Detailserialnumber/Detailserialnumber';
import Createserialnumber from './components/Createserialnumber/Createserialnumber';
import Additionserialnumber from './components/Additionserialnumber/Additionserialnumber';
import Selectplaydata from './components/Selectplaydata/Selectplaydata';
import ContentsList from './components/ContentsList/ContentsList';
import VIvidata from './components/Vividata/VIvidata';
// import Test from './components/Test/Test'

// 인증 확인 함수
const isAuthenticated = () => {
  const token = localStorage.getItem('token');
  if (!token) return false;

  try {
    const { exp } = jwtDecode(token);
    console.log("토큰 만료 시간:", exp, "현재 시간:", Date.now() / 1000);
    return Date.now() < exp * 1000;
  } catch (error) {
    console.error("토큰 디코딩 오류:", error);
    return false;
  }
};

// Private Route 컴포넌트
const PrivateRoute = ({ children }) => {
  return isAuthenticated() ? children : <Navigate to="/Login" />;
};

function AppRoutes() {
  const navigate = useNavigate();

  useEffect(() => {
    const checkTokenExpiration = () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const { exp } = jwtDecode(token);
          if (Date.now() >= exp * 1000) {
            console.log("토큰 만료됨 - 로그아웃 처리");
            localStorage.removeItem('token');
            navigate('/Login');
          }
        } catch (error) {
          console.error("토큰 디코딩 오류:", error);
          // localStorage.removeItem('token');
          navigate('/Login');
        }
      }
    };

    checkTokenExpiration();
    const interval = setInterval(checkTokenExpiration, 1000);
    return () => clearInterval(interval);
  }, [navigate]);

  return (
    <Routes>
      <Route
       path="/Login"
       element={<Login />}
       />
      
      <Route
        path="/cmp"
        element={<PrivateRoute><Cmpage /></PrivateRoute>} 
      />
      <Route
        path="/cmap"
        element={<PrivateRoute><CMAddPage /></PrivateRoute>}
      />
      <Route
        path="/cmdp/:paramsId"
        element={<PrivateRoute><CmDetailPage /></PrivateRoute>}
      />
      <Route
       path='/'
       element={<PrivateRoute><Cmgraphpage /></PrivateRoute>}
       />
      <Route
        path="/updb"
        element={<PrivateRoute><Updateboard /></PrivateRoute>}
      />
      <Route
        path="/nub"
        element={<PrivateRoute><Newupdateboard /></PrivateRoute>}
      />
      <Route
        path="/dub/:paramsId"
        element={<PrivateRoute><Detailupdateboard /></PrivateRoute>}
      />
      <Route
        path="/ssn"
        element={<PrivateRoute><Selectserialnumber /></PrivateRoute>}
      />
      <Route
        path="/dsn/:paramsTable"
        element={<PrivateRoute><Detailserialnumber /></PrivateRoute>}
      />
      <Route
        path="/csn"
        element={<PrivateRoute><Createserialnumber /></PrivateRoute>}
      />
      <Route
        path="/asn"
        element={<PrivateRoute><Additionserialnumber /></PrivateRoute>}
      />
      <Route
        path="/spd"
        element={<PrivateRoute><Selectplaydata /></PrivateRoute>}
      />
      <Route
        path="/cl"
        element={<PrivateRoute><ContentsList /></PrivateRoute>}
      />
      <Route
        path="/vivi"
        element={<PrivateRoute><VIvidata /></PrivateRoute>}
      />
    </Routes>
  );
}



function App() {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );

}

export default App;
