import React, { useCallback, useState } from 'react';
import Logo from "../../assets/images/logo/eyas_logo.png"
import Ft_logo from "../../assets/images/logo/eyas_ft_logo.png"
import './css/login.css'
import '../../styles/core.css'
import { useNavigate } from 'react-router-dom';

function Login() {
  const [userId, setUserId] = useState();
  const [userPassword, setUserPassword] = useState();

  const navigate = useNavigate()

  const doLogin = useCallback(() => {
    fetch('https://ser.eyas.o-r.kr/user/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userId,
        userPassword
      })
    })
      .then(res => res.json())
      .then(({ status, data }) => {
        if (status) {
          // setIsAuthenticated(true);
          const token = data;
          console.log(token)
          localStorage.setItem('token', token); // localStorage에 토큰 저장
          navigate('/')
        } else {
          alert('데이터를 가져오는 데 실패했습니다.');
        }
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
        alert('서버 오류가 발생했습니다.');
      });
  })



  return (
    <div>
      <header className="intro">
        <div className="header_wrap">
          <h1 className="intro_logo">
            <a href="#">
              <img src={Logo} alt="로고" />
            </a>
          </h1>
        </div>
      </header>

      <section id="login_form">
        <div className="int_container">
          <div className="int_wrap">
            <div className="auth-form">
              <h1>로그인</h1>
              <form id="LoginForm" name="LoginForm" method="post" action="none" autoComplete="off" className="Loginform"
                onSubmit={(e) => {
                  e.preventDefault();
                  doLogin();
                }}>
                <div>
                  <div className="form-group">
                    <label htmlFor="user_id" className="hide">아이디 </label>
                    <div className="inputBox">
                      <input id="user_id" type="text"
                        name="user_id" tabIndex="0" autoComplete="off" autoCorrect="off" spellCheck="false" placeholder="아이디를 입력해주세요"
                        onChange={(e) => setUserId(e.target.value)} required />
                    </div>
                  </div>
                  <div className="form-group mt5">
                    <label htmlFor="user_pass" className="hide">비밀번호 </label>
                    <div className="inputBox">
                      <input name="user_pass" id="user_pass" autoComplete="off" autoCorrect="off" spellCheck="false" type="password" placeholder="*******" className=""
                        onChange={(e) => setUserPassword(e.target.value)} required />
                    </div>
                  </div>
                  <div className="form-group mt10">
                    <button type="submit" id="btnLogin" className="btn w100 btn_xl">로그인</button>
                  </div>
                  <div className="find-login">
                    <a href="javascript:void(0);" onClick={() => alert('준비중입니다.')} className="auth-link">아이디/비밀번호 찾기</a>
                    <a href="none" className="auth-link bold">회원가입</a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <footer id="int_ft" className="sub_ft">
        <div className="int_container">
          <div className="int_wrap">
            <h1 className="ft_logo">
              <a href="#none">
                <img src={Ft_logo} alt="로고" />
              </a>
            </h1>
            <ul className="f_list">
              <li className="f_link">
                <a href="javascript:();">
                  이용약관
                </a>
              </li>
              <li className="f_link">
                <a href="javascript:();">
                  <strong>개인정보처리방침</strong>
                </a>
              </li>
              <li>
                <em>©인더텍(주) All Rights Reserved.</em>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Login;
